import React from "react"
import Page from "../components/page"
import Seo from "../components/seo"
import ContentHeader from "../components/content-header"
import HeaderImage from "../../static/company-header.jpg"
import CompanyInfo from "../components/company-info"
import "../styles/company.scss"

export default function CompanyPage() {
  return (
    <Page>
      <Seo title="河内屋-会社情報" description="河内屋の会社情報です" />
      <ContentHeader title="会社情報" description="" bgImage={HeaderImage} />
      <p>河内屋では手作り和菓子をはじめとして、<br />テナント・キッチンカーでの軽食の販売・キッチンカーの派遣を行っています。</p>
      <hr />
      <article>
        <h2>会社概要</h2>
        <CompanyInfo />
      </article>
    </Page>
  )
}
