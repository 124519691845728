import React from "react"
import "../styles/food-card.scss"

export default function FoodCard({title, description, img}) {
    const descriptionBr = description.split('\n').map((line, index) =>
        <>{line}<br /></>
    )
    return (
        <article className="foodCard">
            <div className="imgBlock">
                <img src={img} />
            </div>
            <div className="textBlock">
                <h3>{title}</h3>
                <hr />
                <p>{descriptionBr}</p> 
            </div>
        </article>
    )
}