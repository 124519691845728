import React from "react"
import { Navbar, Nav } from 'react-bootstrap';
import "../styles/header.scss"
import LogoImg from "../../static/logo.png"

export default function Header() {
    return (
        <Navbar collapseOnSelect expand="lg" variant="light" className="navbar">
            <Navbar.Brand href="/" className="navBrand">
                <img  src={LogoImg} className="navBrandImg" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav.Link role="navigation" href="/saleplace" className="navLink">出店募集</Nav.Link>
                <Nav.Link role="navigation" href="/food" className="navLink">手作り菓子</Nav.Link>
                <Nav.Link role="navigation" href="/company" className="navLink">会社情報</Nav.Link>
            </Navbar.Collapse>
        </Navbar>
    )
}