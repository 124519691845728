import React from "react"
import { Helmet } from "react-helmet"
import LogoImg from "../../static/logo.png"
import FaviconImg from "../../static/favicon.png"

const Seo = ({ title, description}) => {
    return (
        <Helmet
            htmlAttributes={{ lang: "ja-jp"}}
            title={title}
            titleTemplate={`%s`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `og:title`,
                    content: title,
                },
                {
                    name: `og:description`,
                    content: description,
                },
                {
                    name: `og:type`,
                    content: `website`,
                },
                {
                    name: `og:image`,
                    content: LogoImg,
                },
                {
                    name: `og:site_name`,
                    content: `河内屋株式会社`,
                },
                {
                    name: `og:locale`,
                    content: `ja_JP`,
                },
            ]}
        >

            <link rel="icon" href={FaviconImg} />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </Helmet>
    )
}

export default Seo