import React from "react"
import { Link } from "gatsby"
import "../styles/food-summary.scss"
import Image from "../../static/manju.png"

export default function FoodSummary() {
    return (
        <article className="foodSummary">
            <img src={Image} />
            <p className="title">河内屋の<span className="titleStrong">手作り菓子</span></p>
            <p className="body">
                河合屋ではこだわりの手作り和菓子を販売しています。<br />
                地元食材を使い、ひとつひとつ心を込めて丁寧に手作りした<br />和菓子です。<br />
            </p>
            <div className="linkButtonWrapper">
                <Link to="/food" className="linkButton">手作り菓子一覧</Link>
            </div>
        </article>
    )
}