import React from "react"

import Page from "../components/page"
import Seo from "../components/seo"
import Hero from "../components/hero"
import PlaceSummary from "../components/place-summary"
import FoodSummary from "../components/food-summary"
import "../styles/index.scss"

export default function IndexPage() {
  return (

    <Page>
      <Seo title="河内屋-ホーム" description="河内屋のホームページです。" />
      <Hero />
      <div className="contentTable">
        <div className="contentCell"><PlaceSummary /></div>
        <div className="contentCell"><FoodSummary /></div>
      </div>
    </Page>
  )
}
