import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/page.scss"

const Page = ({ children }) => {
    return (
        <>
            <Header />
            <section>{children}</section>
            <Footer />
        </>
    )
}

export default Page