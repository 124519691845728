import React from "react"
import "../styles/content-header.scss"

export default function ContentHeader({ title, description, bgImage}) {
    let drawDescription = true;
    if(description === "") {
        drawDescription = false;
    }
    return (
        <div className="contentHeader" style={{ 
            backgroundImage:`url(${bgImage})`,
           }}>
                <h1 className="title">{title}</h1>
                {drawDescription &&
                    <p className="description">{description}</p>
                }
                    
        </div>
    )
}