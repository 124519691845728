import React from "react"
import "../styles/company-info.scss"

export default function CompanyInfo() {
    return (
        <>
            <table className="infoTable">
                <tbody>
                    <tr>
                        <th>会社名</th>
                        <td>株式会社河内屋</td>
                    </tr>
                    <tr>
                        <th>事業内容</th>
                        <td>食品販売・移動販売車派遣</td>
                    </tr>
                    <tr>
                        <th>会社所在地</th>
                        <td>345-0002<br />埼玉県北葛飾郡杉戸町鷲巣709-4</td>
                    </tr>
                    <tr>
                        <th>創業</th>
                        <td>1996年4月1日</td>
                    </tr>
                    <tr>
                        <th>代表者</th>
                        <td>代表取締役 大塚友成</td>
                    </tr>
                    <tr>
                        <th>TEL</th>
                        <td>0480-38-4950（祭日を除く (月)(火)(金) 10:00-17:00）</td>
                    </tr>
                    <tr>
                        <th>mail</th>
                        <td>kawachiyakitchen@gmail.com</td>
                    </tr>
                </tbody>
            </table>
        </>       
    )
}