import React from "react"
import { graphql } from "gatsby"
import Page from "../components/page"
import Seo from "../components/seo"
import ContentHeader from "../components/content-header"
import HeaderImage from "../../static/food-header.jpg"
import FoodCard from "../components/food-card"
import "../styles/food.scss"

export default function CompanyPage({data}) {
  // 表示順番設定
  var orderedFoods = data.allContentfulFood.edges.slice();
  orderedFoods.sort((a, b) => a.node.order - b.node.order);
  return (
    <Page>
      <Seo title="河内屋-手作り菓子" description="河内屋の手作り菓子情報です" />
      <ContentHeader title="手作り菓子" description="" bgImage={HeaderImage} ></ContentHeader>
      <p>河内屋こだわりの手作り菓子を紹介します。</p>
      {orderedFoods.map( edge =>
        <FoodCard title={edge.node.title} description={edge.node.description.description} img={edge.node.image.file.url} /> 
      )}
      <div className="foodPlace">
        <hr />
        <h4>販売店舗</h4>
        <p>下記店舗で販売しております。(五十音順)</p>
        <table className="placeTable">
          <tbody>
            <tr>
              <th>アグリパークゆめすぎ</th>
              <td>埼玉県北葛飾郡杉戸町才羽823-2</td>
            </tr>
            <tr>
              <th>農産物直売所 さくらファーム</th>
              <td>埼玉県幸手市大字内国府間67番1</td>
            </tr>
            <tr>
              <th>道の駅庄和</th>
              <td>埼玉県春日部市上柳９９５</td>
            </tr>
           </tbody>
          </table>
      </div>

    
    </Page>
  )
}

export const query = graphql`
  query allFoodQuery {
    allContentfulFood {
      edges {
        node {
          title
          description {
            description
          }
          image {
            file {
              url
            }
          }
          order
        }
      }
    }
  }
`
