import React from "react"
import { Link } from "gatsby"
import "../styles/hero.scss"
import Logo from "../../static/logo.png"

import bg1 from "../../static/hero-bg/485319.jpg"
import bg2 from "../../static/hero-bg/485361.jpg"
import bg3 from "../../static/hero-bg/485362.jpg"
import bg4 from "../../static/hero-bg/485380.jpg"

export default function Hero() {
    return (
        <div className="hero">
            <div class="bgImg" style={{ 
                backgroundImage:`url(${bg1})`,
                }}></div>
            <div class="bgImg" style={{ 
                backgroundImage:`url(${bg2})`,
                animationDelay:`3s`
                }}></div>
            <div class="bgImg" style={{ 
                backgroundImage:`url(${bg3})`,
                animationDelay:`6s`
                }}></div>
            <div class="bgImg" style={{ 
                backgroundImage:`url(${bg4})`,
                animationDelay:`9s`
                }}></div>
            <img class="content" src={Logo} />
        </div>
    )
}