import React from "react"
import {unified} from 'unified'
import remarkParse from 'remark-parse';
import remarkRehype from "remark-rehype";
import rehypeReact from "rehype-react/lib";
import "../styles/saleplace-card.scss"

export default function SaleplaceCard({title, address, img, description}) {
    const processor = unified()
        .use(remarkParse)
        .use(remarkRehype)
        .use(rehypeReact, {
            createElement: React.createElement,
            components: { 
                h3: 'h5'
            },
        });
    const htmlStr = processor.processSync(description).result;
    return (
        <div className="salePlace">
            <img src={img} />
            <h4>{title}</h4>
            <p>{address}</p>
            {htmlStr}
        </div>
    )
}