import React from "react"
import { Link } from "gatsby"
import "../styles/place-summary.scss"
import Image from "../../static/yatai.png"

export default function PlaceSummary() {
    return (
        <article className="placeSummary">
            <img src={Image} />
            <p className="title">河内屋に<span className="titleStrong">出店</span>する</p>
            <p className="body">
                キッチンカーでの出店者を募集しています。<br />
                北埼玉エリアを中心にキッチンカーでの販売場所を<br />
                提供いたします。
            </p>
            <div className="linkButtonWrapper">
                <Link to="/saleplace" className="linkButton">出店スペース一覧</Link>
            </div>
        </article>
    )
}