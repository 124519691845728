import React from "react"
import { Link } from "gatsby"
import Page from "../components/page"
import Seo from "../components/seo"
import "../styles/404.scss"

export default function NotFound() {
    return (
        <Page>
            <Seo title="404 Not Found" description="ページが見つかりません" />
            <div className="not-found-message">
                <h1>404 Not Found</h1>
                <Link to="/">トップページ</Link>
            </div>
        </Page>
    )
}