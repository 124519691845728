import React from "react"
import { graphql } from "gatsby"
import Page from "../components/page"
import Seo from "../components/seo"
import ContentHeader from "../components/content-header"
import HeaderImage from "../../static/saleplace-header.jpg"
import SaleplaceCard from "../components/saleplace-card"
import "../styles/saleplace.scss"

export default function Saleplace({data}) {
  return (
    <Page>
      <Seo title="河内屋-出店募集" description="河内屋の出展募集情報です" />
      <ContentHeader title="出店募集" description="" bgImage={HeaderImage} />
      <p>
        河内屋ではキッチンカーでの出店者を募集しております。<br />
        河内屋が管理している売り場情報を本ぺージに掲載いたします。
      </p>
      <hr />
      <div className="reserveInfo">
        <p>出店の予約はこちらにご連絡ください</p>
        <h3>0480-38-4950</h3>
        <div className="reserveQualification">
          <p>※出店には下記の資格・検査が必要です。<br />・食品衛生責任者資格者証<br />・営業許可書(販売所の県)<br />・腸内検査書(出店時の6か月以内)</p>
        </div>
        <hr />
      </div>
      {data.allContentfulSalePlace.edges.map( edge =>
        <SaleplaceCard title={edge.node.title} address={edge.node.address.address} img={edge.node.image.file.url} description={edge.node.description.description} /> 
      )}
    </Page>
  )
}

export const query = graphql`
query allSalePlaceQuery {
  allContentfulSalePlace {
    edges {
      node {
        id
        title
        image {
          file {
            url
          }
        }
        addressNumber
        address {
          address
        }
        description {
          description
        }
      }
    }
  }
}

`